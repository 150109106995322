import React, { useState, useEffect, useCallback } from 'react';
import { Steps, Button, Form, Input, message, Row, Col, Select, DatePicker, Radio, Typography, Card, InputNumber } from 'antd';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import debounce from 'lodash/debounce';
import './Formulario.css'
import dataLineaProductiva from './lineas_productivas.json'


//Servicios
import cargarDeptosMunicipios from '../../services/departMunic/deptosMunicipiosServices';
import insertParticipanteEstructuracion from '../../services/estructuracion/insertParticipanteEstructuracionService';
import verificarParticipantePorIdentificacion from '../../services/estructuracion/verificarParticipanteService';

const { Step } = Steps;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const groupLevels = {
  'Grupo A': ['A1', 'A2', 'A3', 'A4', 'A5'],
  'Grupo B': ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7'],
  'Grupo C': ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11', 'C12', 'C13', 'C14', 'C15', 'C16', 'C17', 'C18'],
  'Grupo D': ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10', 'D11', 'D12', 'D13', 'D14', 'D15', 'D16', 'D17', 'D18', 'D19', 'D20', 'D21'],
};

const estadosCiviles = [
  { id: 1, estado: 'Soltero(a)' },
  { id: 2, estado: 'Unión Libre' },
  { id: 3, estado: 'Casado(a)' },
  { id: 4, estado: 'Separado(a)' },
  { id: 5, estado: 'Viudo(a)' },
  { id: 6, estado: 'Madre Cabeza de Familia' },
  { id: 7, estado: 'No reporta' },
  { id: 8, estado: 'Divorciado(a)' },
];

const subregiones = [
  { id: 1, descripcion: "ALTO PATÍA Y NORTE DEL CAUCA" },
  { id: 2, descripcion: "ARAUCA" },
  { id: 3, descripcion: "BAJO CAUCA Y NORDESTE ANTIOQUEÑO" },
  { id: 4, descripcion: "CATATUMBO" },
  { id: 5, descripcion: "CHOCÓ" },
  { id: 6, descripcion: "CUENCA DEL CAGUÁN Y PIEDEMONTE CAQUETEÑO" },
  { id: 7, descripcion: "MACARENA - GUAVIARE" },
  { id: 8, descripcion: "MONTES DE MARÍA" },
  { id: 9, descripcion: "PACÍFICO MEDIO" },
  { id: 10, descripcion: "PACÍFICO Y FRONTERA NARIÑENSE" },
  { id: 11, descripcion: "PUTUMAYO" },
  { id: 12, descripcion: "SIERRA NEVADA - PERIJÁ" },
  { id: 13, descripcion: "SUR DE BOLÍVAR" },
  { id: 14, descripcion: "SUR DE CÓRDOBA" },
  { id: 15, descripcion: "SUR DEL TOLIMA" },
  { id: 16, descripcion: "URABÁ ANTIOQUEÑO" }
];


const Formulario = () => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const [produceOthers, setProduceOthers] = useState(null);
  const [change_in_pollinators, setChange_in_pollinators] = useState(null);
  const [respuesta_participa_produccion_agropecuaria, setRespuesta_participa_produccion_agropecuaria] = useState(null);
  const [respuesta_preven_quedarse_territorio, setRespuesta_preven_quedarse_territorio] = useState(null);
  const [respuesta_actividades_domesticas_afectadas_conflictoArmado, setRespuesta_pactividades_domesticas_afectadas_conflictoArmado] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null); //Estado para el grupo de sisben seleccionado

  //Estados para el paso 2
  const [departamentosxmunicipio, setDepartamentosxmunicipio] = useState('');
  const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState('');
  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [subsectores, setSubsectores] = useState([]);
  const [lineasProductivas, setLineasProductivas] = useState([]);
  const [subsectores_2, setSubsectores_2] = useState([]);
  const [lineasProductivas_2, setLineasProductivas_2] = useState([]);

  //
  const [respuesta_presentan_conflictos_comunidad, setrRespuesta_presentan_conflictos_comunidad] = useState(null);
  const [respuesta_participa_actividades_comunitarias, setrRespuesta_participa_actividades_comunitarias] = useState(null);
  const [respuesta_institucion_presencia_comunidad, setrRespuesta_institucion_presencia_comunidad] = useState(null);


  //
  const [subsectores_3, setSubsectores_3] = useState([]);
  const [lineasProductivas_3, setLineasProductivas_3] = useState([]);
  const [respuesta_transformacion_valor, setrRespuesta_transformacion_valor] = useState(null);
  const [respuesta_acompanamiento_tecnico, setrRespuesta_acompanamiento_tecnico] = useState(null);
  const [respuesta_profundizar_produccion, setrRespuesta_profundizar_produccion] = useState(null);
  const [tipo_poblacion_cual, settipo_poblacion_cual] = useState(false);


  //
  const [respuesta_buenas_practicas_ambientales, setrRespuesta_buenas_practicas_ambientales] = useState(null);
  const [respuesta_implementa_buenas_practicas_ambientales, setrRespuesta_implementa_buenas_practicas_ambientales] = useState(null);
  const [respuesta_areas_conservacion_ambiental, setrRespuesta_areas_conservacion_ambiental] = useState(null);
  const [respuesta_implementa_acciones_haorro_agua, setrRespuesta_implementa_acciones_haorro_agua] = useState(null);
  const [respuesta_acciones_manejo_residuos_solidos, setrRespuesta_acciones_manejo_residuos_solidos] = useState(null);
  const [respuesta_acciones_manejo_residuos_liquidos, setrRespuesta_acciones_manejo_residuos_liquidos] = useState(null);
  const [respuesta_amenaza_orden_climatico_ambiental, setrRespuesta_amenaza_orden_climatico_ambiental] = useState(null);
  const [respuesta_perdidas_sistema_productivo, setrRespuesta_perdidas_sistema_productivo] = useState(null);


  const [especiesMenores, setEspeciesMenores] = useState(null);
  const [hasTechnicalSupport, setHasTechnicalSupport] = useState(null);
  const [conservationActions, setConservationActions] = useState(null);
  const [participatedInSpaces, setParticipatedInSpaces] = useState(null);
  const [healthyEatingSpaces, setHealthyEatingSpaces] = useState(null);
  const [reduceWaste, setReduceWaste] = useState(null);
  const [knowsOrganizations, setKnowsOrganizations] = useState(null);
  const [waterTreatment, setWaterTreatment] = useState(null);
  const [validatingIdentificacion, setValidatingIdentificacion] = useState(false);

  const validateIdentificacion = useCallback(
    debounce(async (identificacion) => {
      if (identificacion) {
        setValidatingIdentificacion(true);
        try {
          const respuesta = await verificarParticipantePorIdentificacion(identificacion);

          if (respuesta.status === 200) {
            // Si el participante ya existe
            form.setFields([
              {
                name: 'numeroIdentificacion',
                errors: ['El participante ya existe.'],
                validateStatus: 'error', // Muestra el mensaje de error
              },
            ]);
          } else {
            // Si el participante no existe
            form.setFields([
              {
                name: 'numeroIdentificacion',
                errors: [], // Limpia errores
                validateStatus: 'success', // Muestra el mensaje de éxito
              },
            ]);
          }
        } catch (error) {
          console.error('Error al verificar el participante:', error);
          message.error('Error al verificar el participante');
        } finally {
          setValidatingIdentificacion(false);
        }
      } else {
        // Si el campo está vacío
        form.setFields([
          {
            name: 'numeroIdentificacion',
            errors: ['Por favor ingrese el número de identificación'],
            validateStatus: 'error', // Muestra el mensaje de error si el campo está vacío
          },
        ]);
      }
    }, 500), // Ajusta el tiempo de debounce según sea necesario
    [form]
  );



  const handleTipoPoblacionChange = (values) => {
    // Verifica si la opción "Otro" está seleccionada
    settipo_poblacion_cual(values.includes('Otro'));
  };

  const handleInputIdentificacionChange = (event) => {
    const { value } = event.target;
    validateIdentificacion(value);
  };


  // Función para obtener lista de departamentos por municipio
  const obtenerdepartamentos = useCallback(async () => {
    try {
      setDepartamentos([]); // Limpia los departamentos seleccionados
      form.setFieldsValue({ departamentos: [] }); // Limpia el campo del formulario
      // Obtener los departamentos x municipio
      const { data } = await cargarDeptosMunicipios();
      setDepartamentosxmunicipio(data);
      const uniqueDepartamentos = data.map(departamento => ({
        id: departamento.oid_departamento,
        nombre: departamento.nombre_departamento,
      }));
      setDepartamentos(uniqueDepartamentos);
    } catch (error) {
      // Manejo de errores
      console.error('Error al obtener departamentos:', error);
    }
  }, [form]);

  useEffect(() => {
    // Inicializar los sectores si hay más de uno
    const sector = dataLineaProductiva.sectores[0]; // Asumiendo que solo tienes un sector en este caso
    if (sector) {
      setSubsectores(sector.subsectores);
      setSubsectores_2(sector.subsectores);
      setSubsectores_3(sector.subsectores);
    }

    console.log('Estado actual del formulario:', formData);

    // Lista de departamentos por municipios
    obtenerdepartamentos();
  }, [formData, obtenerdepartamentos]);

  // Calcula la edad en base a la fecha de nacimiento
  const calculateAge = (dateOfBirth) => {
    const today = moment();
    const birthDate = moment(dateOfBirth);

    // Si birthDate está después de today en el mismo año, la edad debe ser menor
    const age = today.year() - birthDate.year();
    if (today.month() < birthDate.month() ||
      (today.month() === birthDate.month() && today.date() < birthDate.date())) {
      return age - 1;
    }

    return age;
  };

  // Maneja el cambio de la fecha de nacimiento
  const handleDateOfBirthChange = (date) => {


    if (date) {
      const formattedDate = date.format('YYYY-MM-DD'); // Convertir la fecha al formato deseado
      console.log(formattedDate)
      const age = calculateAge(formattedDate);
      console.log("Edad calculada:", age); // Verifica el valor de la edad calculada
      form.setFieldsValue({ edad: age });
    } else {
      form.setFieldsValue({ edad: '' });
    }
  };


  //Maneja el cambio del grupo de sisben
  const handleGroupChange = value => {
    setSelectedGroup(value);
    form.setFieldsValue({ nivel: undefined }); // Reset nivel select
  };


  //maneja el cambio de estado del departamento
  const handleDepartamentoChange = (valor) => {
    setMunicipios([])
    form.setFieldsValue({ municipio: undefined });
    setDepartamentoSeleccionado(valor);
    const lista_municipios = departamentosxmunicipio.find(dep => dep.oid_departamento === valor);
    if (lista_municipios.municipios) {
      setMunicipios(lista_municipios.municipios)
    } else {
      setMunicipios([])
      form.setFieldsValue({ municipio: undefined });

    }
  };

  const handleSectorChange = (value) => {
    // Buscar el sector seleccionado por su ID
    const selectedSector = dataLineaProductiva.sectores.find((sector) => sector.id === value);
    setSubsectores(selectedSector ? selectedSector.subsectores : []);
    form.setFieldsValue({ sub_sector_1: undefined, linea_productiva_1: undefined });
  };

  const handleSubsectorChange = (value) => {
    // Buscar el subsector seleccionado por su ID
    const selectedSubsector = subsectores.find((subsector) => subsector.id === value);
    setLineasProductivas(selectedSubsector ? selectedSubsector.lineas_productivas : []);
    form.setFieldsValue({ linea_productiva_1: undefined });
  };


  const handleSector_2Change = (value) => {
    // Buscar el sector seleccionado por su ID
    const selectedSector = dataLineaProductiva.sectores.find((sector) => sector.id === value);
    setSubsectores_2(selectedSector ? selectedSector.subsectores : []);
    form.setFieldsValue({ sub_sector_2: undefined, linea_productiva_2: undefined });
  };

  const handleSubsector_2Change = (value) => {
    // Buscar el subsector seleccionado por su ID
    const selectedSubsector = subsectores_2.find((subsector) => subsector.id === value);
    setLineasProductivas_2(selectedSubsector ? selectedSubsector.lineas_productivas : []);
    form.setFieldsValue({ linea_productiva_2: undefined });
  };


  const handleSector_3Change = (value) => {
    // Buscar el sector seleccionado por su ID
    const selectedSector = dataLineaProductiva.sectores.find((sector) => sector.id === value);
    setSubsectores_3(selectedSector ? selectedSector.subsectores : []);
    form.setFieldsValue({ sub_sector_proyecto: undefined, linea_productiva_proyecto: undefined });
  };

  const handleSubsector_3Change = (value) => {
    // Buscar el subsector seleccionado por su ID
    const selectedSubsector = subsectores_3.find((subsector) => subsector.id === value);
    setLineasProductivas_3(selectedSubsector ? selectedSubsector.lineas_productivas : []);
    form.setFieldsValue({ linea_productiva_proyecto: undefined });
  };

  const next = async () => {
    try {
      // Valida los campos del formulario
      const values = await form.validateFields();

      if (current === 0 && values.numeroIdentificacion) {
        // Verifica si el número de identificación es válido
        const respuesta = await verificarParticipantePorIdentificacion(values.numeroIdentificacion);
        if (respuesta.status === 200) {
          // Si el participante ya existe, no permitir avanzar
          form.setFields([
            {
              name: 'numeroIdentificacion',
              errors: ['El participante ya existe.'],
              validateStatus: 'error',
            },
          ]);
          return;
        }
      }

      // Si la validación es exitosa, actualiza el estado con los nuevos datos
      setFormData((prevData) => ({
        ...prevData,
        ...values,
      }));

      // Avanza al siguiente paso
      setCurrent(current + 1);
    } catch (error) {
      // Si hay errores en la validación, los errores se manejarán automáticamente
      console.error('Error al validar el formulario:', error);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleFinish = async () => {
    try {
      const values = await form.validateFields();

      const finalData = {
        ...formData,
        ...values,
      };

      setFormData(finalData);

      console.log('Enviando datos a la API:', finalData);

      // Simular envío a la API
      const respuesta = await insertParticipanteEstructuracion(finalData);

      if (respuesta.status === 200) {
        message.success(respuesta.message);
        form.resetFields(); // Limpiar el formulario
        setCurrent(0);

      } else {
        message.warning(respuesta.message);
      }

      console.log(respuesta);
    } catch (error) {
      console.error('Error:', error);
      message.error('Ha ocurrido un error inesperado');
    }
  };

  const steps = [

  
    {
      title: 'Datos Basícos',
      content: (

        <div>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="nombre"
                label="Nombres"
                rules={[{ required: true, message: 'Por favor ingrese su nombre' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="apellido"
                label="Apellidos"
                rules={[{ required: true, message: 'Por favor ingrese su apellido' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="tipoIdentificacion"
                label="Tipo de Identificación"
                rules={[{ required: true, message: 'Por favor seleccione el tipo de identificación' }]}
              >
                <Select placeholder="Seleccionar tipo">
                  <Option value={1}>Cédula de Ciudadania</Option>
                  <Option value={2}>Tarjeta de Identidad</Option>
                  <Option value={3}>Pasaporte</Option>
                  <Option value={4}>Cedula de Extranjeria</Option>
                </Select>
              </Form.Item>
            </Col>


            <Col xs={24} sm={12}>
              <Form.Item
                name="numeroIdentificacion"
                label="Número de Identificación"
                rules={[
                  { required: true, message: 'Por favor ingrese el número de identificación' },
                  { max: 10, message: 'El número de identificación no puede tener más de 10 dígitos' }
                ]}
              >
                <Input
                  type='number'
                  onChange={handleInputIdentificacionChange}
                  disabled={validatingIdentificacion}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={6}>
              <Form.Item
                name="fechaExpedicion"
                label="Fecha de Expedición"
                rules={[{ required: true, message: 'Por favor seleccione la fecha de expedición' }]}
              >
                <DatePicker format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                name="fechaNacimiento"
                label="Fecha de Nacimiento"
                rules={[{ required: true, message: 'Por favor seleccione su fecha de nacimiento' }]}
              >
                <DatePicker format="DD/MM/YYYY" onChange={handleDateOfBirthChange} />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                name="edad"
                label="Edad"
                rules={[{ required: true, message: 'La edad se calcula automáticamente a partir de la fecha de nacimiento' }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Item
                name="sexo"
                label="Sexo"
                rules={[{ required: true, message: 'Por favor seleccione su sexo' }]}
              >
                <Select placeholder="Seleccionar sexo">
                  <Option value="Hombre">Hombre</Option>
                  <Option value="Mujer">Mujer</Option>
                  <Option value="otro">Otro</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12}>
              <Form.Item
                name="ingresosMensuales"
                label="Ingresos Mensuales"
                rules={[{ required: true, message: 'Por favor ingrese sus ingresos mensuales' }]}
              >
                <NumericFormat
                  thousandSeparator=","
                  decimalSeparator="."
                  prefix="$"
                  suffix=" COP"
                  allowNegative={false}
                  customInput={Input}
                  displayType="input"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="sabeLeerEscribir"
                label="¿Sabe Leer o Escribir?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SI">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>

              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="lineaEconomica"
                label="Línea Económica Principal"
                rules={[{ required: true, message: 'Por favor seleccione la línea económica principal' }]}
              >
                <TextArea Row={3} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="nivel_escolaridad"
                label="Nivel de escolaridad"
                rules={[{ required: true, message: 'Por favor seleccione nivel de escolaridad' }]}
              >
                <Select placeholder="Seleccionar nivel de escolaridad">
                  <Option value="Primaria">Primaria</Option>
                  <Option value="Segundaria">Segundaria</Option>
                  <Option value="Tecnólogo">Tecnólogo/a</Option>
                  <Option value="Técnica">Técnica</Option>
                  <Option value="Profesional">Profesional</Option>
                  <Option value="Ninguna">Ninguna</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="tipo_poblacion"
                label="Tipo de Población"
                rules={[{ required: true, message: 'Por favor seleccione un tipo de población' }]}
              >
                <Select
                  mode='multiple'
                  placeholder="Seleccionar tipo de población"
                  onChange={handleTipoPoblacionChange}
                >
                  <Option value="Campesino/a">Campesino/a</Option>
                  <Option value="Comunidades y pueblos">Comunidades y pueblos</Option>
                  <Option value="Comunidad NARP">Comunidad NARP</Option>
                  <Option value="Comunidad ROM">Comunidad ROM</Option>
                  <Option value="Víctima del conflicto">Víctima del conflicto</Option>
                  <Option value="Personas reintegradas: acogidas en el marco de justicia y paz (AUC, FARC y ELN por ley 975)">Personas reintegradas: acogidas en el marco de justicia y paz (AUC, FARC y ELN por ley 975)</Option>
                  <Option value="Personas restituidas/en proceso de restitución de tierras">Personas restituidas/en proceso de restitución de tierras</Option>
                  <Option value="Discapacidad">Discapacidad</Option>
                  <Option value="Mujer Rural">Mujer Rural</Option>
                  <Option value="Joven Rural">Joven Rural</Option>
                  <Option value="Otro">Otro</Option>
                </Select>
              </Form.Item>
            </Col>

            {tipo_poblacion_cual && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="otro_tipo_poblacion"
                  label="Especificar otro tipo de población"
                  rules={[{ required: true, message: 'Por favor especifique el tipo de población' }]}
                >
                  <Input placeholder="Especificar" />
                </Form.Item>
              </Col>
            )}
          </Row>


          <Row>
            <Col xs={24} sm={24}>
              <div style={{ backgroundColor: '#328BC7', color: 'white', padding: '8px', fontSize: '16px', fontWeight: 'bold', border: '1px solid #007bff', textAlign: 'left' }}>
                DATOS DE CONTACTO DEL PARTICIPANTE
              </div>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: '16px' }}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="direccion_correspondencia"
                label="Dirección de Correspondencia"
                rules={[{ required: true, message: 'Por favor ingrese Dirección de Correspondencia' }]}
              >
                <Input type='text' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="correo_electronico"
                label="Correo Electrónico"
                rules={[{ required: true, type: "email", message: 'Por favor ingrese Correo Electrónico' }]}
              >
                <Input type='email' />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12}>
              <Form.Item
                name="numero_telefono_1"
                label="Número Teléfono de Contacto 1"
                rules={[{ required: true, message: 'Por favor ingrese Número Teléfono de Contacto 1' }]}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12}>
              <Form.Item
                name="numero_telefono_2"
                label="Número Teléfono de Contacto 2"
                rules={[{ required: true, message: 'Por favor ingrese Número Teléfono de Contacto 2' }]}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
          </Row>



          <Row>
            <Col xs={24} sm={24}>
              <div style={{ backgroundColor: '#328BC7', color: 'white', padding: '8px', fontSize: '16px', fontWeight: 'bold', border: '1px solid #007bff', textAlign: 'left' }}>
                DATOS DE CONECTIVIDAD
              </div>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: '16px' }}>
            <Col xs={12} sm={12}>
              <Form.Item
                name="telefonica"
                label="Telefónica"
                rules={[{ required: true, message: 'Por favor ingrese Telefónica' }]}
              >
                <Radio.Group>
                  <Radio value="SI">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12}>
              <Form.Item
                name="internet"
                label="Internet"
                rules={[{ required: true, message: 'Por favor ingrese internet' }]}
              >
                <Radio.Group>
                  <Radio value="SI">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

          </Row>
        </div>

      ),
    },

    {
      title: 'Información Familiar',
      content: (

        <Row gutter={16}>
          <Col xs={12} sm={8}>
            <Form.Item
              name="grupo_sisben"
              label="Grupo de sisben"
              rules={[{ required: true, message: 'Por favor ingrese su grupo de sisben' }]}
            >
              <Select placeholder="Seleccionar grupo de sisben" onChange={handleGroupChange}>
                {Object.keys(groupLevels).map(group => (
                  <Option key={group} value={group}>
                    {group}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} sm={8}>
            <Form.Item
              name="nivel_grupo"
              label="Nivel"
              rules={[{ required: true, message: 'Por favor ingrese su nivel' }]}
            >
              <Select placeholder="Seleccionar nivel" disabled={!selectedGroup}>
                {selectedGroup &&
                  groupLevels[selectedGroup].map(level => (
                    <Option key={level} value={level}>
                      {level}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={12} sm={8}>
            <Form.Item
              name="jefe_familia"
              label="Es Jefe/a de Familia"
              rules={[{ required: true, message: 'Por favor ingrese Es Jefe/a de Familia' }]}
            >
              <Radio.Group >
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="total_personas_cargo"
              label="No. Personas a Cargo"
              rules={[{ required: true, message: 'Por favor ingrese No. Personas a Cargo' }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="total_nucleo_familia"
              label="Total número de personas del núcleo familiar"
              rules={[{ required: true, message: 'Por favor ingrese Total número de personas del núcleo familiar' }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="estado_civil"
              label="Estado Civil"
              rules={[{ required: true, message: 'Por favor ingrese su Estado Civil' }]}
            >
              <Select placeholder="Seleccionar estado civil">
                {estadosCiviles.map(({ id, estado }) => (
                  <Option key={id} value={id}>
                    {estado}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="nombre_conyuge"
              label="Nombre Completo Cónyuge, si lo tiene"
            >
              <Input type="text" />
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="tipo_identificacion_conyuge"
              label="Tipo de Identificación del cónyuge"
            >
              <Select placeholder="Seleccionar tipo">
                <Option value={1}>Cédula de Ciudadania</Option>
                <Option value={2}>Tarjeta de Identidad</Option>
                <Option value={3}>Pasaporte</Option>
                <Option value={4}>Cedula de Extranjeria</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="numero_identificacion_conyuge"
              label="No. Identificación del cónyuge"
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="numero_contacto_conyuge"
              label="Número de Contacto del cónyugue"
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="tiempo_libre_descanso"
              label="¿Cuánto tiempo libre tiene en el día para su descanso?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Cuánto tiempo libre tiene en el día para su descanso?' }]}
            >
              <Input type="text" />
            </Form.Item>
          </Col>

          <Col xs={24} span={12}>
            <Form.Item
              name="que_hace_tiempo_libre"
              label="¿Qué hace en ese tiempo libre?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Qué hace en ese tiempo libre?' }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="desarrolla_actividades_domesticas"
              label="¿En el hogar quien desarrolla las actividades domésticas y de cuidado? (Hombres o Mujeres)"
              rules={[{ required: true, message: 'Por favor ingrese ¿En el hogar quiÉn desarrolla las actividades domésticas y de cuidado? (Hombres o Mujeres)' }]}
            >
              <Radio.Group >
                <Radio value="Hombres">Hombres</Radio>
                <Radio value="Mujeres">Mujeres</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="participa_produccion_agropecuaria"
              label="¿Usted participa en la producción agropecuaria de la familia?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Usted participa en la producción agropecuaria de la familia?' }]}
            >
              <Radio.Group onChange={(e) => setRespuesta_participa_produccion_agropecuaria(e.target.value)}
                value={respuesta_participa_produccion_agropecuaria} >
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>


          {respuesta_participa_produccion_agropecuaria === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_participa_produccion_agropecuaria"
                label="¿Si la respuesta es sí, mencione que actividades desarrolla?"
                rules={[{ required: true, message: 'Por favor ingrese ¿Si la respuesta es sí, mencione que actividades desarrolla?' }]}
              >
                <TextArea Row={4} />
              </Form.Item>
            </Col>
          )}


          <Col xs={24} sm={12}>
            <Form.Item
              name="horas_desarrolla_actividades_domesticas"
              label="¿Cuántas horas al día le dedica a las actividades domésticas y de cuidado ?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Cuántas horas al día le dedica a las actividades domésticas y de cuidado ?' }]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="actividades_domesticas_afectadas_conflictoArmado"
              label="¿Las actividades agropecuarias de su familia en algún momento fueron afectadas por el conflicto armado?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Las actividades agropecuarias de su familia en algún momento fueron afectadas por el conflicto armado?' }]}
            >
              <Radio.Group onChange={(e) => setRespuesta_pactividades_domesticas_afectadas_conflictoArmado(e.target.value)}
                value={respuesta_actividades_domesticas_afectadas_conflictoArmado}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>

            </Form.Item>
          </Col>

          {respuesta_actividades_domesticas_afectadas_conflictoArmado === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_actividades_domesticas_afectadas_conflictoArmado"
                label="Describa cómo fueron afectadas las actividades"
                rules={[{ required: true, message: 'Por favor Describa cómo fueron afectadas las actividades' }]}
              >
                <Input.TextArea rows={4} />

              </Form.Item>
            </Col>
          )}


          <Col xs={24} sm={12}>
            <Form.Item
              name="preven_quedarse_territorio"
              label="¿Las personas jóvenes (hasta los 28 años) de su hogar prevén quedarse en el territorio?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Las personas jóvenes (hasta los 28 años) de su hogar prevén quedarse en el territorio?' }]}
            >
              <Radio.Group onChange={(e) => setRespuesta_preven_quedarse_territorio(e.target.value)}
                value={respuesta_preven_quedarse_territorio}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_preven_quedarse_territorio === 'NO' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_preven_quedarse_territorio"
                label="A donde prefieren irsen"
                rules={[{ required: true, message: 'Por favor ingresa a donde prefieren irsen' }]}
              >
                <Input.TextArea rows={4} />

              </Form.Item>
            </Col>
          )}
        </Row>
      ),
    },
    {
      title: 'INFORMACIÓN DEL PROYECTO A ESTRUCTURAR',
      content: (
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="codigo_iniciativa_art"
              label="Código iniciativa  ART"
              rules={[{ required: true, message: 'Por favor ingrese Código iniciativa  ART' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="nombre_proyecto"
              label="Nombre del proyecto a estructurar"
              rules={[{ required: true, message: 'Por favor ingrese Nombre del proyecto a estructurar' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="subregion_pdet"
              label="Subregión PDET"
              rules={[{ required: true, message: 'Por favor ingrese Subregión PDET' }]}
            >
              <Select placeholder="Seleccionar subregion pdet">
                {subregiones.map(({ id, descripcion }) => (
                  <Option key={id} value={id}>
                    {descripcion}
                  </Option>
                ))}
              </Select>
            </Form.Item>

          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="departamento"
              label="Departamento"
              rules={[{ required: true, message: 'Por favor ingrese Departamento ' }]}
            >
              <Select
                placeholder="Seleccione un departamento"
                onChange={handleDepartamentoChange}
                value={departamentoSeleccionado}
              >
                {departamentos.map((d) => (
                  <Option key={d.id} value={d.id}>{d.nombre}</Option>
                ))}
              </Select>
            </Form.Item>

          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="municipio"
              label="Municipio"
              rules={[{ required: true, message: 'Por favor ingrese Municipio' }]}
            >
              <Select disabled={!departamentoSeleccionado}>
                {municipios.map(mun => (
                  <Option key={mun.oid_municipio} value={mun.oid_municipio}>{mun.nombre_municipio}</Option>
                ))}
              </Select>
            </Form.Item>

          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="tipo_zona_influencia"
              label="Tipo Zona de Influencia Directa"
              rules={[{ required: true, message: 'Por favor ingrese Tipo Zona de Influencia Directa' }]}
            >
              <Select placeholder="Seleccionar tipo de zona de influencia">
                <Option value="Centro poblado">Centro poblado</Option>
                <Option value="Corregimiento">Corregimiento</Option>
                <Option value="Resguardo indígena">Resguardo indígena</Option>
                <Option value="Consejo comunitario">Consejo comunitario</Option>
                <Option value="Vereda">Vereda</Option>

              </Select>
            </Form.Item>

          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="nombre_zona_influencia"
              label="Nombre de Zona de influencia directa"
              rules={[{ required: true, message: 'Por favor ingrese Nombre de Zona de influencia directa ' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="codigo_shape_art"
              label="Código Shape Veredas ART"
              rules={[{ required: true, message: 'Por favor ingrese Código Shape Veredas ART' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="nombre_predio"
              label="Nombre del Predio/Finca"
              rules={[{ required: true, message: 'Por favor ingrese Nombre del Predio/Finca' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="relacion_tenencia_predio"
              label="Relación de Tenencia con el Predio"
              rules={[{ required: true, message: 'Por favor ingrese Relación de Tenencia con el Predio' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={12} sm={8}>
            <Form.Item
              name="georeferencia_latitud"
              label="Georeferenciación - Latitud"
              rules={[{ required: true, message: 'Por favor ingrese Georeferenciación - Latitud ' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={12} sm={8}>
            <Form.Item
              name="georeferencia_longitud"
              label="Georeferenciación - Longitud"
              rules={[{ required: true, message: 'Por favor ingrese Georeferenciación - Longitud ' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name="area_destinada_proyecto"
              label="Área destinada al proyecto (ha)"
              rules={[{ required: true, message: 'Por favor ingrese Área destinada al proyecto ' }]}
            >
              <Input type='number' />
            </Form.Item>

          </Col>


          <Col xs={24} sm={24}>
            <div style={{ backgroundColor: '#328BC7', color: 'white', padding: '8px', fontSize: '16px', fontWeight: 'bold', border: '1px solid #007bff', textAlign: 'left' }}>
              LINEA PRODUCTIVA 1
            </div>
          </Col>


          <Col xs={24} sm={8}>
            <Form.Item
              name="sector_1"
              label="Sector"
              rules={[{ required: true, message: 'Por favor ingrese sector' }]}
            >
              <Select placeholder="Seleccionar sector" onChange={handleSectorChange}>
                {dataLineaProductiva.sectores.map((sector) => (
                  <Option key={sector.id} value={sector.id}>
                    {sector.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name="sub_sector_1"
              label="Sub Sector"
              rules={[{ required: true, message: 'Por favor ingrese sub sector' }]}
            >
              <Select placeholder="Seleccionar sub sector" onChange={handleSubsectorChange}>
                {subsectores.map((subsector) => (
                  <Option key={subsector.id} value={subsector.id}>
                    {subsector.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name="linea_productiva_1"
              label="Línea Productiva 1 Proyecto"
              rules={[{ required: true, message: 'Por favor ingrese Línea Productiva 1' }]}
            >
              <Select placeholder="Seleccionar línea productiva 1">
                {lineasProductivas.map((linea) => (
                  <Option key={linea.id} value={linea.id}>
                    {linea.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


          <Col xs={24} sm={24}>
            <div style={{ backgroundColor: '#328BC7', color: 'white', padding: '8px', fontSize: '16px', fontWeight: 'bold', border: '1px solid #007bff', textAlign: 'left' }}>
              LINEA PRODUCTIVA 2
            </div>
          </Col>


          <Col xs={24} sm={8}>
            <Form.Item
              name="sector_2"
              label="Sector"
              rules={[{ required: true, message: 'Por favor ingrese sector' }]}
            >
              <Select placeholder="Seleccionar sector" onChange={handleSector_2Change}>
                {dataLineaProductiva.sectores.map((sector) => (
                  <Option key={sector.id} value={sector.id}>
                    {sector.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name="sub_sector_2"
              label="Sub Sector"
              rules={[{ required: true, message: 'Por favor ingrese sub sector' }]}
            >
              <Select placeholder="Seleccionar sub sector" onChange={handleSubsector_2Change}>
                {subsectores_2.map((subsector) => (
                  <Option key={subsector.id} value={subsector.id}>
                    {subsector.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name="linea_productiva_2"
              label="Línea Productiva 2"
              rules={[{ required: true, message: 'Por favor ingrese Línea Productiva 2' }]}
            >
              <Select placeholder="Seleccionar línea productiva 2">
                {lineasProductivas_2.map((linea) => (
                  <Option key={linea.id} value={linea.id}>
                    {linea.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>


          <Col xs={12} sm={12}>
            <Form.Item
              name="distancia_lugar_cabecera_municipal"
              label="Distancia Lugar del Proyecto a Cabecera Municipal - (Kilómetros)"
              rules={[{ required: true, message: 'Por favor ingrese Distancia Lugar del Proyecto a Cabecera Municipal - (Kilómetros)' }]}
            >
              <Input type='number' />
            </Form.Item>

          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="tiempo_lugar_cabecera_municipal"
              label="Tiempo Lugar del Proyecto a Cabecera Muninicpal - (Minutos)"
              rules={[{ required: true, message: 'Por favor ingrese  Tiempo Lugar del Proyecto a Cabecera Muninicpal - (Minutos)' }]}
            >
              <Input type='number' />
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="medio_transporte"
              label="Medio de Transporte"
              rules={[{ required: true, message: 'Por favor ingrese  Medio de Transporte' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="vive_mismo_lugar_proyecto"
              label="¿Vive en el mismo lugar del Proyecto?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Vive en el mismo lugar del Proyecto?' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="lugar_vivienda"
              label="¿Lugar de Vivienda?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Lugar de Vivienda?' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="eslabon_cadena_productiva_mujeres"
              label="¿En cuál eslabón de la cadena productiva participan las mujeres?"
              rules={[{ required: true, message: 'Por favor ingrese ¿En cuál eslabón de la cadena productiva participan las mujeres?' }]}
            >
              <Select placeholder="Seleccionar eslabon de cadena productiva">
                <Option value="Siembra">Siembra</Option>
                <Option value="Cosecha">Cosecha</Option>
                <Option value="Agregación de valor/Transformación">Agregación de valor/Transformación</Option>
                <Option value="Comercialización">Comercialización</Option>
              </Select>
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="eslabon_cadena_productiva_jovenes"
              label="¿En cuál eslabón de la cadena productiva participan los y las jóvenes?"
              rules={[{ required: true, message: 'Por favor ingrese ¿En cuál eslabón de la cadena productiva participan los y las jóvenes?' }]}
            >
              <Select placeholder="Seleccionar eslabon de cadena productiva">
                <Option value="Siembra">Siembra</Option>
                <Option value="Cosecha">Cosecha</Option>
                <Option value="Agregación de valor/Transformación">Agregación de valor/Transformación</Option>
                <Option value="Comercialización">Comercialización</Option>
              </Select>
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="comunidad_cuenta_resguardos"
              label="¿La comunidad cuenta con Resguardo,Territorios colectivos constituidos y/o ZRC?"
              rules={[{ required: true, message: 'Por favor ingrese ¿La comunidad cuenta con Resguardo,Territorios colectivos constituidos y/o ZRC? ' }]}

            >
              <Select mode="multiple" placeholder="Seleccionar comunidad">
                <Option value="Resguardo">Resguardo</Option>
                <Option value="Territorios colectivos constituidos">Territorios colectivos constituidos</Option>
                <Option value="ZRC">ZRC</Option>
              </Select>
            </Form.Item>

          </Col>
        </Row>

      ),
    },

    {
      title: 'BANCARIZACIÓN',
      content: (

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="tipo_establecimiento_bancario"
              label="Tipo Establecimiento Bancario cercano a su Vivienda"
              rules={[{ required: true, message: 'Por favor ingrese Tipo Establecimiento Bancario cercano a su Vivienda' }]}
            >
              <Select placeholder="Seleccionar tipo de establecimiento bancario">
                <Option value="Oficina física">Oficina física</Option>
                <Option value="orresponsal bancario">Corresponsal bancario</Option>

              </Select>
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="Tipo de entidad"
              label="Tipo de Entidad"
              rules={[{ required: true, message: 'Por favor ingrese tipo de entidad' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>


          <Col xs={12} sm={12}>
            <Form.Item
              name="cuenta_productos_financieros"
              label="¿Cuenta con Productos Financieros?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Cuenta con Productos Financieros?' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>


          <Col xs={12} sm={12}>
            <Form.Item
              name="cuenta_ahorro"
              label="Cuenta de Ahorro"
              rules={[{ required: true, message: 'Por favor ingrese Cuenta de Ahorro' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>


          <Col xs={12} sm={12}>
            <Form.Item
              name="tarjeta_credito"
              label="Tarjeta de Crédito"
              rules={[{ required: true, message: 'Por favor ingrese Tarjeta de Crédito' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>


          <Col xs={12} sm={12}>
            <Form.Item
              name="otro_credito"
              label="Otros creditos"
              rules={[{ required: true, message: 'Por favor ingrese otros creditos' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>


          <Col xs={24} sm={12}>
            <Form.Item
              name="capacitacion_educacion_financiera"
              label="¿Ha recibido Capacitación en Educación Financiera?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Ha recibido Capacitación en Educación Financiera?' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>


          <Col xs={24} sm={12}>
            <Form.Item
              name="interesado_informacion_financiera"
              label="¿Interesado en  Información de Inclusión Financiera?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Interesado en  Información de Inclusión Financiera?' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>


        </Row>
      ),
    },

    {
      title: 'INFORMACIÓN ORGANIZACIONAL Y COMUNITARIA',
      content: (

        <Row gutter={16}>
          <Col xs={12} sm={8}>
            <Form.Item
              name="vinculado_alguna_organizacion"
              label="¿Vinculado a alguna Organización?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Vinculado a alguna Organización?' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>
          <Col xs={12} sm={8}>
            <Form.Item
              name="tipo_organizacion"
              label="Tipo de Organización"
              rules={[{ required: true, message: 'Por favor ingrese Tipo de Organización' }]}
            >

              <Select placeholder="Seleccionar tipo de organizacion">
                <Option value="unta de Acción Comunal">Junta de Acción Comunal</Option>
                <Option value="onsejo Comunitario">Consejo Comunitario</Option>
                <Option value="Asociación">Asociación</Option>

              </Select>
            </Form.Item>

          </Col>

          <Col xs={12} sm={8}>
            <Form.Item
              name="nombre_organizacion"
              label="Nombre de la Organización"
              rules={[{ required: true, message: 'Por favor ingrese Nombre de la Organización ' }]}
            >
              <Input type='text' />
            </Form.Item>

          </Col>

          <Col xs={12} sm={12}>
            <Form.Item
              name="organizacion_mujeres"
              label="Es una organización de Mujeres?"
              rules={[{ required: true, message: 'Por favor ingrese Es una organización de Mujeres?' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="organizacion_conflicto_armado"
              label="Es una organización de víctimas del conflicto armado?"
              rules={[{ required: true, message: 'Por favor ingrese Es una organización de víctimas del conflicto armado?' }]}
            >
              <Radio.Group>
                <Radio value="SI">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="presentan_conflictos_comunidad"
              label="¿Considera usted que se presentan conflictos en su comunidad?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Considera usted que se presentan conflictos en su comunidad?' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_presentan_conflictos_comunidad(e.target.value)}
                value={respuesta_presentan_conflictos_comunidad}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>


            </Form.Item>

          </Col>

          {respuesta_presentan_conflictos_comunidad === 'SÍ' && (


            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_presentan_conflictos_comunidad"
                label="Describa el conflicto"
                rules={[{ required: true, message: 'Por favor Describa el conflicto' }]}
              >
                <TextArea Row={4} />

              </Form.Item>

            </Col>
          )}

          <Col xs={24} sm={12}>
            <Form.Item
              name="participa_actividades_comunitarias"
              label="¿Usted participa de actividades comunitarias?"
              rules={[{ required: true, message: 'Por favor ingrese ¿Usted participa de actividades comunitarias?' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_participa_actividades_comunitarias(e.target.value)}
                value={respuesta_participa_actividades_comunitarias}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>

            </Form.Item>

          </Col>

          {respuesta_participa_actividades_comunitarias === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_participa_actividades_comunitarias"
                label="Mencione de cuáles"
                rules={[{ required: true, message: 'Por favor mencione de cuáles' }]}
              >
                <TextArea Row={4} />

              </Form.Item>

            </Col>
          )}

          <Col xs={24} sm={12}>
            <Form.Item
              name="comunidad_cuenta_con"
              label="¿La comunidad cuenta con: plan de vida, plan de salvaguarda, plan de etnodesarrollo o plan de desarrollo sostenible?"
              rules={[{ required: true, message: 'Por favor ingrese ' }]}
            >
              <Select mode="multiple" placeholder="Seleccionar tipo">
                <Option value="Plan de vida">Plan de vida</Option>
                <Option value="plan de salvaguarda">plan de salvaguarda</Option>
                <Option value="Plan de etnodesarrollo">Plan de etnodesarrollo</Option>
                <Option value="Plan de desarrollo sostenible">Plan de desarrollo sostenible</Option>

              </Select>
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="estan_organizados_en"
              label="¿ Están organizados en Resguardo, Cabildo o Consejo Comunitario?"
              rules={[{ required: true, message: 'Por favor ingrese ¿ Están organizados en Resguardo, Cabildo o Consejo Comunitario? ' }]}
            >
              <Select placeholder="Seleccionar tipo">
                <Option value="Resguardo">Resguardo</Option>
                <Option value="Cabildo">Cabildo</Option>
                <Option value="Consejo Comunitario">Consejo Comunitario</Option>

              </Select>
            </Form.Item>

          </Col>

          <Col xs={24} sm={24}>
            <Form.Item
              name="ejercen_practicas_tradicionales"
              label="¿ Ejercen prácticas tradicionales y ancestrales en el territorio? ¿cuales?"
              rules={[{ required: true, message: 'Por favor ingrese ¿ Ejercen prácticas tradicionales y ancestrales en el territorio? ¿cuales?' }]}
            >
              <TextArea Row={4} />
            </Form.Item>

          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="institucion_presencia_comunidad"
              label="¿Alguna institución pública o privada hace presencia en su comunidad?"
              rules={[{ required: true, message: 'Por favor ingrese  ¿Alguna institución pública o privada hace presencia en su comunidad?' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_institucion_presencia_comunidad(e.target.value)}
                value={respuesta_institucion_presencia_comunidad}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>

          </Col>

          {respuesta_institucion_presencia_comunidad === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_institucion_presencia_comunidad"
                label="Mencione cuáles"
                rules={[{ required: true, message: 'Por favor mencione cuáles' }]}
              >
                <TextArea Row={4} />

              </Form.Item>

            </Col>
          )}
        </Row>
      ),
    },

    {
      title: 'IINFORMACIÓN PRODUCTIVA DE LA ACTIVIDAD SELECCIONADA PARA EL PROYECTO',
      content: (

        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              name="sector_proyecto"
              label="Sector"
              rules={[{ required: true, message: 'Por favor ingrese sector' }]}
            >
              <Select placeholder="Seleccionar sector" onChange={handleSector_3Change}>
                {dataLineaProductiva.sectores.map((sector) => (
                  <Option key={sector.id} value={sector.id}>
                    {sector.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name="sub_sector_proyecto"
              label="Sub Sector"
              rules={[{ required: true, message: 'Por favor ingrese sub sector' }]}
            >
              <Select placeholder="Seleccionar sub sector" onChange={handleSubsector_3Change}>
                {subsectores_3.map((subsector) => (
                  <Option key={subsector.id} value={subsector.id}>
                    {subsector.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              name="linea_productiva_proyecto"
              label="Línea Productiva del proyecto"
              rules={[{ required: true, message: 'Por favor ingrese línea productiva del proyecto' }]}
            >
              <Select placeholder="Seleccionar línea productiva del proyecto">
                {lineasProductivas_3.map((linea) => (
                  <Option key={linea.id} value={linea.id}>
                    {linea.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24}>
            <Form.Item
              name="variedades_razas"
              label="Variedades o razas que tiene en la finca"
              rules={[{ required: true, message: 'Por favor ingrese las variedades o razas' }]}
            >
              <TextArea Row={4} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="area_total"
              label="Área total de la finca (ha)"
              rules={[{ required: true, message: 'Por favor ingrese el área total' }]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="servicios_publicos"
              label="El predio cuenta con servicios públicos agua y luz?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="area_sembrada"
              label="Área sembrada en el producto principal relacionado con el proyecto (ha)"
              rules={[{ required: true, message: 'Por favor ingrese el área sembrada' }]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="analisis_suelos"
              label="Cuenta con analisis de suelos de al menos el último año?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="topografia"
              label="Topografía del predio"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Select placeholder="Seleccionar opción">
                <Option value="pendiente">Pendiente</Option>
                <Option value="muy_pendiente">Muy pendiente</Option>
                <Option value="ondulada">Ondulada</Option>
                <Option value="plana">Plana</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="fuente_agua"
              label="Fuente principal de agua para riego"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Select placeholder="Seleccionar opción">
                <Option value="Acueducto">Acueducto</Option>
                <Option value="Agua lluvia">Agua lluvia</Option>
                <Option value="Pozo">Pozo</Option>
                <Option value="Ciénaga">Ciénaga</Option>
                <Option value="Río">Río</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="regimen_precipitacion"
              label="Régimen de precipitación"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Select placeholder="Seleccionar opción">
                <Option value="Monomodal">Monomodal</Option>
                <Option value="Bimodal">Bimodal</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="densidad_siembra"
              label="Densidad de siembra (árboles por ha o kg de semilla/ha)"
              rules={[{ required: true, message: 'Por favor ingrese la densidad de siembra' }]}
            >
              <Input type='text' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="edad_cultivo"
              label="Edad del cultivo (Sólo para especies de ciclo largo)"
            >
              <Input type='text' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="produccion"
              label="¿Está en producción?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="cantidad_producida"
              label="Cantidad producida el año pasado (Kg)"
              rules={[{ required: true, message: 'Por favor ingrese la cantidad producida' }]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="registros_actividades"
              label="¿Lleva registros de actividades y costos de la actividad productiva?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="costo_produccion"
              label="¿Cuál es el costo de producción por Kg de producto?"
              rules={[{ required: true, message: 'Por favor ingrese el costo de producción' }]}
            >
              <NumericFormat
                thousandSeparator=","
                decimalSeparator="."
                prefix="$"
                suffix=" COP"
                allowNegative={false}
                customInput={Input}
                displayType="input"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="transformacion_valor"
              label="¿Hace algún tipo de transformación o agregación de valor?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_transformacion_valor(e.target.value)}
                value={respuesta_transformacion_valor} >
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_transformacion_valor === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_transformacion_valor"
                label="Descríba lo que realiza para transformar o agregar valor"
                rules={[{ required: true, message: 'Por favor Descríba lo que realiza para transformar o agregar valor' }]}
              >
                <TextArea Row={4} />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={12}>
            <Form.Item
              name="participacion_transformacion"
              label="¿Quién participa en la transformación o agregación de valor?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group  >
                <Radio value="Hombre">Hombre</Radio>
                <Radio value="Mujer">Mujer</Radio>
              </Radio.Group>

            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="costos_transformacion"
              label="¿Cuáles son los costos de la transformación por kg de producto?"
              rules={[{ required: true, message: 'Por favor ingrese los costos de transformación' }]}
            >
              <NumericFormat
                thousandSeparator=","
                decimalSeparator="."
                prefix="$"
                suffix=" COP"
                allowNegative={false}
                customInput={Input}
                displayType="input"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="producto_final"
              label="¿Cuál es su producto final?"
              rules={[{ required: true, message: 'Por favor ingrese el producto final' }]}
            >
              <TextArea Row={4} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="municipios_comercializacion"
              label="¿En cuáles municipios realiza la comercialización de los productos?"
              rules={[{ required: true, message: 'Por favor ingrese los municipios' }]}
            >
              <Input type='text' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="compradores_producto"
              label="¿A quién o quiénes le vende el producto final?"
              rules={[{ required: true, message: 'Por favor ingrese los compradores' }]}
            >
              <TextArea Row={4} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="precio_unitario"
              label="¿Cuál es el precio unitario de venta de cada kg de producto final?"
              rules={[{ required: true, message: 'Por favor ingrese el precio unitario' }]}
            >
              <Input type='number' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="problematicas"
              label="¿Cuáles considera que son las principales problemáticas de su actividad productiva en su finca o municipio?"
              rules={[{ required: true, message: 'Por favor ingrese las problemáticas' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="alternativas_solucion"
              label="¿Qué alternativas de solución considera las más adecuadas?"
              rules={[{ required: true, message: 'Por favor ingrese las alternativas de solución' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="venta_produccion"
              label="¿En dónde o a quién le gustaría vender su producción?"
              rules={[{ required: true, message: 'Por favor ingrese el lugar de venta' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="lugar_compra_insumos"
              label="Lugar de compra de insumos"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Select placeholder="Seleccionar opción">
                <Option value="cabecera_municipal">Cabecera municipal</Option>
                <Option value="Capital">Capital</Option>
                <Option value="Otros">Otros</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="infraestructura_equipos"
              label="Para reactivar o mejorar su producción, indique qué infraestructuras, equipos, materiales o suministros considera que son los más importantes"
              rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="acompanamiento_tecnico"
              label="¿Durante el último año recibió acompañamiento técnico o agroempresarial?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_acompanamiento_tecnico(e.target.value)}
                value={respuesta_acompanamiento_tecnico}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_acompanamiento_tecnico === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_acompanamiento_tecnico"
                label="¿En qué temas?"
                rules={[{ required: true, message: 'Por favor ingrese los temas' }]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={12}>
            <Form.Item
              name="quien_acompanamiento"
              label="¿Quién realizó ese acompañamiento?"
              rules={[{ required: true, message: 'Por favor ingrese el nombre de quien realizó el acompañamiento' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24}>
            <Form.Item
              name="temas_formacion"
              label="¿En qué temas le gustaría recibir acompañamiento o formación? (por ejemplo productivo agropecuario, finanzas, administración, alimentación adecuada, mujer rural, juventud, TICs, asociatividad, etc.)"
              rules={[{ required: true, message: 'Por favor ingrese los temas' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="lugar_compra"
              label="¿Dónde compra los insumos que utiliza para el desarrollo de la actividad agropecuaria?"
              rules={[{ required: true, message: 'Por favor ingrese el lugar' }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="profundizar_produccion"
              label="¿Le gustaría profundizar en temas asociado a la producción agropecuaria?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_profundizar_produccion(e.target.value)}
                value={respuesta_profundizar_produccion}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>


          {respuesta_profundizar_produccion === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_profundizar_produccion"
                label="mencione que temas."
                rules={[{ required: true, message: 'Por favormencione que temas.' }]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={12}>
            <Form.Item
              name="comentarios_adicionales"
              label="Comentarios adicionales del encuestador/a"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

        </Row>
      ),
    },
    {
      title: 'SERVICIOS TURÍSTICOS',
      content: (

        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              name="tipo_organizacion_turistico"
              label="Tipo de organización"
              rules={[{ required: true, message: 'Por favor seleccione el tipo de organización' }]}
            >
              <Select placeholder="Seleccionar opción">
                <Option value="Con ánimo de lucro">Con ánimo de lucro</Option>
                <Option value="Sin ánimo de lucro">Sin ánimo de lucro</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="tiempo_trabajando"
              label="¿Cuánto tiempo lleva la organización trabajando en temas turísticos?"
              rules={[{ required: true, message: 'Por favor ingrese el tiempo' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="principal_servicio"
              label="¿Cuál es el principal servicio turístico que ofrece?"
              rules={[{ required: true, message: 'Por favor seleccione un servicio' }]}
            >
              <Select mode="multiple" placeholder="Seleccionar opción">
                <Option value="Alojamiento">Alojamiento</Option>
                <Option value="Alimentación">Alimentación</Option>
                <Option value="Operación turística">Operación turística</Option>
                <Option value="Guianza">Guianza</Option>
                <Option value="Transporte turístico">Transporte turístico</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="camara_comercio"
              label="¿La Organización cuenta con Cámara de Comercio?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="registro_turismo"
              label="¿La organización cuenta con Registro Nacional de Turismo?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>



        </Row>
      ),
    },
    {
      title: 'DERECHO HUMANO A LA ALIMENTACIÓN ADECUADA (DHAA)',
      content: (
        <div>
          <Row gutter={16}>

            <Col xs={24} sm={12}>
              <Form.Item
                name="produce_others"
                label="Además de la línea productiva principal ¿Producen otros alimentos en su predio?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >

                <Radio.Group onChange={(e) => setProduceOthers(e.target.value)}
                  value={produceOthers}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>

              </Form.Item>
            </Col>
            {produceOthers === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="other_products_area"
                  label="¿Cuáles y en qué área?"
                  rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}
            {produceOthers === 'NO' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="why_not_produce"
                  label="¿Por qué?"
                  rules={[{ required: true, message: 'Por favor ingrese el motivo' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12}>
              <Form.Item
                name="cria_especies_menores"
                label="¿Tiene cría especies menores en su finca?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >

                <Radio.Group onChange={(e) => setEspeciesMenores(e.target.value)}
                  value={especiesMenores}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {especiesMenores === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="especies_menores"
                  label="Si respondió que sí, ¿Cuáles?"
                  rules={[{ required: true, message: 'Por favor ingrese las especies' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={12}>
              <Form.Item
                name="autoconsumo"
                label="De los alimentos y especies menores producidos/criadas en la finca ¿qué porcentaje destinan al autoconsumo?"
                rules={[{ required: true, message: 'Por favor ingrese el porcentaje' }]}
              >
                <InputNumber
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="acompanamiento_tecnico"
                label="¿Cuentan con acompañamiento técnico u otras medidas para que su modelo productivo incorpore prácticas agroecológicas? (Diversificación de cultivos y especies animales, cuidado y protección de fuentes de agua y bosques, manejo sostenible de residuos sólidos, producción para autoconsumo, protección y conservación del suelo con materia orgánica, producción local de bioinsumos, uso y protección de semillas y razas criollas)"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >

                <Radio.Group onChange={(e) => setHasTechnicalSupport(e.target.value)}
                  value={hasTechnicalSupport}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>

              </Form.Item>
            </Col>
            {hasTechnicalSupport === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="de_quien_acompanamiento"
                  label="Si la respuesta es sí, ¿de quién recibe ese acompañamiento?"
                  rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12}>
              <Form.Item
                name="acciones_conservacion"
                label="¿Implementan acciones de conservación e intercambio de semillas nativas y/o criollas, así como prácticas y saberes asociados a estas?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >

                <Radio.Group onChange={(e) => setConservationActions(e.target.value)}
                  value={conservationActions}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {conservationActions === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="acciones_descripcion"
                  label="Si la respuesta es sí, descríbalas."
                  rules={[{ required: true, message: 'Por favor ingrese la descripción' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}


            <Col xs={24} sm={12}>
              <Form.Item
                name="espacios_tradicionales"
                label="¿Ha participado en espacios que promuevan la recuperación y fortalecimiento de los saberes tradicionales en torno a la alimentación?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >

                <Radio.Group onChange={(e) => setParticipatedInSpaces(e.target.value)}
                  value={participatedInSpaces}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {participatedInSpaces === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="espacios_indicacion"
                  label="Si la respuesta es sí, indique cuáles."
                  rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={24}>
              <Form.Item
                name="cambios_conocimientos"
                label="¿Qué cambios ha percibido en los conocimientos y prácticas asociadas a la preparación y consumo de alimentos propios del territorio? (Si no ha percibido indique Ninguno)"
                rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
              >
                <TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="practicas_saludables"
                label="¿Ha participado en espacios que promuevan la adopción de prácticas de alimentación saludables?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >

                <Radio.Group onChange={(e) => setHealthyEatingSpaces(e.target.value)}
                  value={healthyEatingSpaces}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>

              </Form.Item>
            </Col>
            {healthyEatingSpaces === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="practicas_saludables_detalles"
                  label="Si respondió que sí, menciónelos. Por ejemplo: espacios de formación institucionales (salud, ICBF, alcaldía), espacios comunitarios y organizativos, ONGs, academia, privados) "
                  rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12}>
              <Form.Item
                name="reduce_waste"
                label="¿Realiza prácticas para reducir el desperdicio de alimentos?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group onChange={(e) => setReduceWaste(e.target.value)}
                  value={reduceWaste}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {reduceWaste === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="reduce_waste_detalles"
                  label="Si la respuesta es sí, menciónelas."
                  rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12}>
              <Form.Item
                name="conoce_organizaciones"
                label="¿Conoce alguna organización que trabaje en su comunidad o municipio alrededor del DHAA o soberanía alimentaria?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group onChange={(e) => setKnowsOrganizations(e.target.value)}
                  value={knowsOrganizations}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {knowsOrganizations === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="organizaciones_detalles"
                  label="Si la respuesta es sí, indique cuál."
                  rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12}>
              <Form.Item
                name="water_treatment"
                label="¿Realiza algún tratamiento al agua para el consumo?"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >

                <Radio.Group onChange={(e) => setWaterTreatment(e.target.value)}
                  value={waterTreatment}>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                </Radio.Group>


              </Form.Item>
            </Col>
            {waterTreatment === 'SÍ' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="water_treatment_detalles"
                  label="Si la respuesta es sí, mencione cuál."
                  rules={[{ required: true, message: 'Por favor ingrese los detalles' }]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            )}

          </Row>

          <Row>
            <Col xs={24} sm={24}>
              <div style={{ backgroundColor: '#328BC7', color: 'white', padding: '8px', fontSize: '16px', fontWeight: 'bold', border: '1px solid #007bff', textAlign: 'left' }}>
                FIES: Durante los últimos 12 meses, DEBIDO A LA FALTA DE DINERO U OTROS RECURSOS hubo algún momento en que usted u otra persona del hogar:
              </div>
            </Col>
          </Row>
          <Row gutter={16}>

            <Col xs={24} sm={12}>
              <Form.Item
                name="suficiente_comida"
                label="1. Se preocupó por no tener suficientes alimentos para comer"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                  <Radio value="Ns-Nr">Ns-Nr</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="alimentos_saludables_nutritivos"
                label="2. No pudo comer alimentos saludables y nutritivos"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                  <Radio value="Ns-Nr">Ns-Nr</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="variedad_alimentos"
                label="3. Consumió poca variedad de alimentos"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                  <Radio value="Ns-Nr">Ns-Nr</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="saltar_comida"
                label="4. Tuvo que saltar una comida (desayuno, almuerzo, comida o cena)"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                  <Radio value="Ns-Nr">Ns-Nr</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="comio_menos_comida"
                label="5. Comió menos de lo que pensaba que debía comer"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                  <Radio value="Ns-Nr">Ns-Nr</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="hogar_quedo_sin_alimentos"
                label="6. El hogar se quedó sin alimentos"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                  <Radio value="Ns-Nr">Ns-Nr</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="hambre_pero_no_comio"
                label="7. Tuvo hambre pero no comió"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                  <Radio value="Ns-Nr">Ns-Nr</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="no_comio_dia_entero"
                label="8. No comió en un día entero"
                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
              >
                <Radio.Group>
                  <Radio value="SÍ">SÍ</Radio>
                  <Radio value="NO">NO</Radio>
                  <Radio value="Ns-Nr">Ns-Nr</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <div style={{ backgroundColor: '#328BC7', color: 'white', padding: '8px', fontSize: '16px', fontWeight: 'bold', border: '1px solid #007bff', textAlign: 'left' }}>
                Por favor mencione los tipos de alimentos (comidas  y refrigerios) que usted o cualquiera de los integrante de su familia comieron  o bebieron durante el día de ayer por el día y por la noche en el hogar.  Comience por la primera comida o bebida que tomaron por la mañana. NO OLVIDE INCLUIR BEBIDAS
              </div>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: '16px' }}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="desayuno"
                label="Desayuno (Hora, Alimentos consumidos  en casa)"
                rules={[{ required: true, message: 'Por favor ingresa información solicitada' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="desayuno_refrigerio"
                label="Refrigerio (Hora, Alimentos consumidos  en casa)"
                rules={[{ required: true, message: 'Por favor ingresa información solicitada' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="almuerso"
                label="Almuerzo (Hora, Alimentos consumidos  en casa)"
                rules={[{ required: true, message: 'Por favor ingresa información solicitada' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="almuerzo_refrigerio"
                label="Refrigerio (Hora, Alimentos consumidos  en casa)"
                rules={[{ required: true, message: 'Por favor ingresa información solicitada' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="cena"
                label="Cena (Hora, Alimentos consumidos  en casa)"
                rules={[{ required: true, message: 'Por favor ingresa información solicitada' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name="cena_refrigerio"
                label="Refrigerio (Hora, Alimentos consumidos  en casa)"
                rules={[{ required: true, message: 'Por favor ingresa información solicitada' }]}
              >
                <Input />
              </Form.Item>
            </Col>

          </Row>
        </div>
      ),
    },
    {
      title: 'ASPECTOS AMBIENTALES',
      content: (

        <Row gutter={16}>
          <Col xs={24} sm={24}>
            <Form.Item
              name="principales_problematicas_ambientales"
              label="¿Cuáles considera son las principales problemáticas ambientales del territorio? Ejemplo: Deforestación, erosión y perdida de suelo, disminución de la biodiversidad, contaminación del agua, expansión de la frontera agrícola, entre otras."
              rules={[{ required: true, message: 'Por favor ingrese las problemáticas ambientales' }]}
            >
              <TextArea placeholder="Ingrese las problemáticas ambientales" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="change_in_pollinators"
              label="¿Considera que ha cambiado el número de polinizadores como aves e insectos en el territorio?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setChange_in_pollinators(e.target.value)}
                value={change_in_pollinators}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>

            </Form.Item>
          </Col>

          {change_in_pollinators === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="pollinators_change_explanation"
                label="Si la respuesta es sí, sustente"
                dependencies={['change_in_pollinators']}
                rules={[{ required: true, message: 'Por favor sustente el cambio en el número de polinizadores' }]}
              >
                <TextArea placeholder="Sustente el cambio en el número de polinizadores" />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={12}>
            <Form.Item
              name="buenas_practicas_ambientales"
              label="¿Conoce usted sobre buenas prácticas ambientales?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_buenas_practicas_ambientales(e.target.value)}
                value={respuesta_buenas_practicas_ambientales}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_buenas_practicas_ambientales === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_buenas_practicas_ambientales"
                label="Si la respuesta es sí, sustente"
                rules={[{ required: true, message: 'Por favor sustente buenas practicas ambientales' }]}
              >
                <TextArea placeholder="Sustente el cambio en el número de polinizadores" />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={24}>
            <Form.Item
              name="implementa_buenas_practicas_ambientales"
              label="¿Implementa buenas prácticas ambientales en la actividad productiva que desarrolla? Ejemplo: Manejo y conservación de suelos, uso mínimo de insumos químicos, protección de fuentes hídricas, labranza mínima, ahorro y uso eficiente del recurso hídrico, conservación de áreas boscosas, aprovechamiento de residuos orgánicos, clasificación y disposición de residuos sólidos inorgánicos."
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_implementa_buenas_practicas_ambientales(e.target.value)}
                value={respuesta_implementa_buenas_practicas_ambientales}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {respuesta_implementa_buenas_practicas_ambientales === 'SÍ' && (
            <Col xs={24} sm={24}>
              <Form.Item
                name="respuesta_implementa_buenas_practicas_ambientales"
                label="Si la respuesta es sí, sustente"
                rules={[{ required: true, message: 'Por favor sustente buenas practicas ambientales' }]}
              >
                <TextArea placeholder="Sustente las buenas practicas ambientales" />
              </Form.Item>
            </Col>
          )}


          <Col xs={24} sm={24}>
            <Form.Item
              name="figuras_de_proteccion"
              label="¿Cuáles son las figuras de protección o manejo ambiental en el entorno de su predio? Ejemplo: (Parque Nacional Natural, Distrito de Manejo Integrado, Planes de ordenación y manejo de cuencas hídricas - POMCAS, Áreas de reserva forestal de Ley 2da, Reservas naturales de la sociedad civil, entre otros.)"
              rules={[{ required: true, message: 'Por favor ingrese las figuras de protección o manejo ambiental' }]}
            >
              <TextArea placeholder="Ingrese las figuras de protección o manejo ambiental" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24}>
            <Form.Item
              name="necesidades_de_capacitacion"
              label="¿Cuáles considera son las mayores necesidades de capacitación y formación en temas ambientales de su comunidad? Ejemplo: Conservación y uso eficiente del agua, manejo y conservación de los suelos, eficiencia energética, manejo de residuos, mitigación del cambio climático, etc."
              rules={[{ required: true, message: 'Por favor ingrese las necesidades de capacitación y formación en temas ambientales' }]}
            >
              <TextArea placeholder="Ingrese las necesidades de capacitación y formación en temas ambientales" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="areas_conservacion_ambiental"
              label="¿Ha destinado áreas de su predio para la conservación ambiental?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_areas_conservacion_ambiental(e.target.value)}
                value={respuesta_areas_conservacion_ambiental}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_areas_conservacion_ambiental && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_areas_conservacion_ambiental"
                label={
                  respuesta_areas_conservacion_ambiental === 'SÍ'
                    ? 'Explique indicando el área destinada:'
                    : '¿Por qué no lo ha realizado?'
                }
                rules={[
                  {
                    required: true,
                    message: 'Por favor explique su respuesta',
                  },
                ]}
              >
                <TextArea placeholder="Explique su respuesta" />
              </Form.Item>
            </Col>
          )}


          <Col xs={24} sm={12}>
            <Form.Item
              name="implementa_acciones_haorro_agua"
              label="¿Implementa acciones para la cosecha, el ahorro y uso eficiente del agua?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_implementa_acciones_haorro_agua(e.target.value)}
                value={respuesta_implementa_acciones_haorro_agua}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_implementa_acciones_haorro_agua && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_implementa_acciones_haorro_agua"
                label={
                  respuesta_implementa_acciones_haorro_agua === 'SÍ'
                    ? 'Explique:'
                    : '¿Por qué no lo ha realiza?'
                }
                rules={[
                  {
                    required: true,
                    message: 'Por favor explique su respuesta',
                  },
                ]}
              >
                <TextArea placeholder="Explique su respuesta" />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={12}>
            <Form.Item
              name="acceden_agua_consumo_humano"
              label="¿Cómo acceden al agua para consumo humano?"
              rules={[{ required: true, message: 'Por favor explique cómo acceden al agua para consumo humano' }]}
            >
              <TextArea placeholder="Explique cómo acceden al agua para consumo humano" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="acceden_agua_consumo_productivo"
              label="¿Cómo acceden al agua para uso productivo?"
              rules={[{ required: true, message: 'Por favor explique cómo acceden al agua para uso productivo' }]}
            >
              <TextArea placeholder="Explique cómo acceden al agua para uso productivo" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="acciones_manejo_residuos_solidos"
              label="¿Implementa acciones de manejo de residuos sólidos?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_acciones_manejo_residuos_solidos(e.target.value)}
                value={respuesta_acciones_manejo_residuos_solidos}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_acciones_manejo_residuos_solidos && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_acciones_manejo_residuos_solidos"
                label={
                  respuesta_acciones_manejo_residuos_solidos === 'SÍ'
                    ? 'Explique:'
                    : '¿Por qué no lo ha realiza?'
                }
                rules={[
                  {
                    required: true,
                    message: 'Por favor explique su respuesta',
                  },
                ]}
              >
                <TextArea placeholder="Explique su respuesta" />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={12}>
            <Form.Item
              name="acciones_manejo_residuos_liquidos"
              label="¿Implementa acciones de manejo de residuos líquidos?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_acciones_manejo_residuos_liquidos(e.target.value)}
                value={respuesta_acciones_manejo_residuos_liquidos}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_acciones_manejo_residuos_liquidos && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_acciones_manejo_residuos_liquidos"
                label={
                  respuesta_acciones_manejo_residuos_liquidos === 'SÍ'
                    ? 'Explique:'
                    : '¿Por qué no lo ha realiza?'
                }
                rules={[
                  {
                    required: true,
                    message: 'Por favor explique su respuesta',
                  },
                ]}
              >
                <TextArea placeholder="Explique su respuesta" />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={12}>
            <Form.Item
              name="amenaza_orden_climatico_ambiental"
              label="¿Su sistema productivo se ha visto afectado por alguna amenaza del orden climático-ambiental?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_amenaza_orden_climatico_ambiental(e.target.value)}
                value={respuesta_amenaza_orden_climatico_ambiental}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {respuesta_amenaza_orden_climatico_ambiental === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_amenaza_orden_climatico_ambiental"
                label="¿Cuál? Ejemplos: Remoción en masa, deslizamientos, incendios forestales, inundaciones, avenidas torrenciales, escases de agua."
                rules={[{ required: true, message: 'Por favor sustente' }]}
              >
                <TextArea placeholder="Sustente las amenazas climaticas" />
              </Form.Item>
            </Col>
          )}



          <Col xs={24} sm={24}>
            <Form.Item
              name="implementado_acciones_enfrendar_dichas_amenazas"
              label="¿Ha implementado acciones para enfrentar dichas amenazas? Ejemplos: Mitigación del efecto de desabastecimiento hídrico, cosechas de agua, estabilización de escorrentías, capacitación y equipamiento para el control de incendios forestales."
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <TextArea Row={3} />
            </Form.Item>
          </Col>



          <Col spaxs={24} sm={12}>
            <Form.Item
              name="perdidas_sistema_productivo"
              label="¿Ha sufrido daños y/o pérdidas en su sistema productivo debido a estas situaciones?"
              rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
            >
              <Radio.Group onChange={(e) => setrRespuesta_perdidas_sistema_productivo(e.target.value)}
                value={change_in_pollinators}>
                <Radio value="SÍ">SÍ</Radio>
                <Radio value="NO">NO</Radio>
              </Radio.Group>

            </Form.Item>
          </Col>

          {respuesta_perdidas_sistema_productivo === 'SÍ' && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="respuesta_perdidas_sistema_productivo"
                label="Sustentar las perdidas del sistema productivo"
                dependencies={['change_in_pollinators']}
                rules={[{ required: true, message: 'Por favor sustentar las perdidas del sistema productivo' }]}
              >
                <TextArea placeholder="Sustentar las perdidas del sistema productivo" />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={12}>
            <Form.Item
              name="instituciones_acompanado_orden_ambiental"
              label="¿Cuáles instituciones han acompañado el desarrollo de acciones del orden ambiental en el territorio? Sepárelas por coma"
              rules={[{ required: true, message: 'Por favor ingrese las instituciones que han acompañado el desarrollo de acciones ambientales' }]}
            >
              <TextArea placeholder="Ingrese las instituciones que han acompañado el desarrollo de acciones ambientales" />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Confirmar',
      content: (
        <div>
          <p>Revise la información antes de enviar.</p>
        </div>
      ),
    },
  ];

  return (
    <div className="form-container">
      <Card className="form-card">
        <div className="step-content">
          <Title level={2} className="main-title">Registrar Participante</Title>
          <Title level={3} className="step-title">{steps[current].title}</Title>
        </div>
        <Steps current={current}>
          {steps.map((_, index) => (
            <Step key={index} />
          ))}
        </Steps>
        <div className="steps-content" style={{ marginTop: '24px' }}>
          <Form form={form} layout="vertical">
            {steps[current].content}
          </Form>
        </div>
        <div className="steps-action" style={{ marginTop: '24px', textAlign: 'center' }}>
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={prev}>
              Anterior
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Siguiente
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={handleFinish}>
              Enviar
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};


export default Formulario;